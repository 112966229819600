<template>
  <div class="container-outer overwrite">
    <main id="full">
			<section class="standard-layout login headings-reset">
        <div class="outer-height-full">
          <div class="flex-weird-container d-none d-lg-flex">
            <img src="~@/assets/img/logo-dt-large.svg" alt="Logo Direct Transport">
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 text-center d-lg-none">
                <img src="~@/assets/img/logo-dt-large.svg" alt="Logo Direct Transport">
              </div>
              <div class="col-12 col-lg-6 offset-lg-6">
                <div class="outer outer-login">
                  <h4 class="title">Inloggen</h4>
                  <div class="form-group">
                    <label>E-mailadres</label>
                    <input type="email" class="form-control" v-model="login.user">
                  </div>
                  <div class="form-group password">
                    <label>Wachtwoord<router-link to="/password-recover">Wachtwoord vergeten?</router-link></label>
                    <input type="password" class="form-control" v-model="login.pass">
                  </div>
                  <button class="btn-new btn-primary float-right" @click="doLogin" :disabled="!login.user || !login.pass || loading">Inloggen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
			</section>
    </main>
  </div>
  <Notification :is-error="notifyError" :title="notifyTitle" :text="notifyText" v-show="showNotification"/>
</template>

<script>

import Notification from '@/components/Notification';

export default {
  components: {
    Notification
  },
  props: ['user'],
  data () {
    return {
      loading: false,
      login: {
        user: '',
        pass: ''
      },
      showNotification: false,
      notifyError: false,
      notifyTitle: '',
      notifyText: ''
    }
  },
  methods: {
    doLogin () {
      this.loading = true;

      fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.login),
      })
        .then(response => response.ok ? response.json() : null)
        .then(data => {

          if (data) {
            let cookieDate = new Date();

            cookieDate.setTime(cookieDate.getTime() + 86400000);
            document.cookie = 'mylox-token=' + data['token'] + '; expires=' + cookieDate.toUTCString() + '; path=/';

            this.$store.commit('setLoggedIn', true);
            this.$store.commit('setUser', data);

            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect });
            } else {

              switch (data['profile']) {
                case 'admin':
                  this.$router.push({ path: '/dashboard/admin-index' });
                  break;
                case 'courier':
                  this.$router.push({ path: '/dashboard/courier-index' });
                  break;
                case 'customer':
                  this.$router.push({ path: '/dashboard/customer-index' });
                  break;
              }
            }
          } else {
            this.notifyTitle = 'Inloggen mislukt';
            this.notifyText = 'Gebruikersnaam en/of wachtwoord onjuist.';
            this.notifyError = true;
            this.showNotification = true;

            setTimeout(() => {
              this.showNotification = false;
            }, 3000);
          }

          this.loading = false;
        });
    }
  },
  mounted () {
    let cookieDate = new Date();

    cookieDate.setTime(cookieDate.getTime() - 86400000);
    document.cookie = 'mylox-token=; expires=' + cookieDate.toUTCString() + '; path=/';
    this.$store.commit('setLoggedIn', false);
  }
}
</script>
